<template>
    <div class="topup-page">
        <div class="breadcrumb">
            <span>Dashboard</span> > <span>Top up</span>
        </div>
        <div class="topup-container">
            <div class="topup-form">
                <h2>Add Unit to your account</h2>
                <div class="form-group">
                    <label for="amount">Amount <span>*</span></label>
                    <div class="input-group">
                        <span class="currency">RWF</span>
                        <input type="text" id="amount" v-model="amount" placeholder="Minimum topup: 1000" />
                    </div>
                </div>
                <button :disabled="!isAmountValid" @click="openPhoneNumberModal" class="process-button">
                    Proceed to Pay
                </button><br>
                <p v-if="amount && !isAmountValid" class="error-message">Amount must be at least 1000 RWF.</p>

                <p v-if="isAmountValid" class="sms-units" style="color: green;">
                    You will receive <b>{{ smsUnits }}</b> SMS units for this amount.
                </p>
            </div>


            <div class="topup-history">
                <h3>Top-up History</h3>
                <table>
                    <thead>
                        <tr>
                            <th>SMS Price</th>
                            <th>Amount (RWF)</th>
                            <th>Balance</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="record in topupHistory" :key="record.id">
                            <td>20</td>
                            <td>{{ record.amount }}</td>
                            <td>1,948</td>
                            <td>{{ formatDate(record.created_at) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- Phone Number Modal -->
        <div v-if="showModal" class="modal-overlay">
            <div class="modal">
                <h2>Confirm Your Phone Number</h2>
                <div class="form-group">
                    <label for="phone-number">Phone Number <span>*</span></label>
                    <input type="text" id="phone-number" v-model="phoneNumber" placeholder="Enter your phone number" />
                </div>
                <div class="button-container">
                    <button @click="processPayment" class="process-button">
                        <span v-if="loading" class="spinner"></span>
                        Pay
                    </button>
                    <button @click="closeModal" class="cancel-button">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
import { useToast } from "vue-toastification";
import axios from "@/helpers/axios.js";

const Toast = useToast();

export default {
    data() {
        return {
            amount: '',
            phoneNumber: '',
            showModal: false,
            loading: false,
            topupHistory: [],
        };
    },
    computed: {
        isAmountValid() {
            return this.amount && Number(this.amount) >= 1000;
        },
        smsUnits() {
            if (this.isAmountValid) {
                return Math.floor(Number(this.amount) / 20);
            }
            return 0;
        },
    },
    created() {
        this.fetchTopupHistory();
    },
    methods: {
        openPhoneNumberModal() {
            if (this.isAmountValid) {
                this.showModal = true;
            } else {
                Toast.error("Please enter a valid amount (minimum 1000 RWF).");
            }
        },
        closeModal() {
            this.showModal = false;
            this.phoneNumber = '';
        },
        fetchTopupHistory() {
            axios.get('/bulksms/top-up')
                .then(response => {
                    if (response.data.status) {
                        this.topupHistory = response.data.data;
                    } else {
                        Toast.error("Failed to retrieve top-up history.");
                    }
                })
                .catch(error => {
                    console.log("An error occurred while fetching top-up history.");
                });
        },
        formatDate(date) {
            return new Date(date).toLocaleDateString();
        },
        async processPayment() {
            this.loading = true;
            try {
                const response = await axios.post('/bulksms/top-up', {
                    amount: this.amount,
                    phone_number: this.phoneNumber,
                });

                if (response.data.status) {
                    Toast.success(response.data.message);
                } else {
                    Toast.error(response.data.message);
                }
            } catch (error) {
                console.log("An error occurred while processing the payment.");
            } finally {
                this.loading = false;
                this.closeModal();
            }
        },
    },
};



</script>

<style scoped>
.topup-page {
    padding: 20px;
    background-color: #f9f9f9;
}

.breadcrumb {
    font-size: 16px;
    color: #7d7d7d;
    margin-bottom: 20px;
}

.topup-container {
    display: flex;
    gap: 20px;
}

.topup-form {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    flex: 1;
}

.topup-form h2 {
    font-size: 20px;
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    font-weight: bold;
    font-size: 14px;
}

.input-group {
    display: flex;
    align-items: center;
}

.currency {
    background-color: #e5e5e5;
    padding: 10px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

input[type="text"] {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-left: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.process-button {
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.process-button:hover {
    background-color: #218838;
}

.topup-info {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    flex: 1;
}

.topup-info h3 {
    color: #0A2472;
    margin-bottom: 20px;
}

.topup-history {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    flex: 1;
}

.topup-history h3 {
    color: #0A2472;
    margin-bottom: 20px;
    text-align: center;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
}

th {
    background-color: #195093;
    color: white;
}

td {
    background-color: #f9f9f9;
}




.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    width: 500px;
    height: auto;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.modal h2 {
    margin-bottom: 20px;
    font-size: 24px;
    text-align: center;
    color: #0A2472;
}

.form-group {
    margin-bottom: 20px;
}

#phone-number {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

.cancel-button {
    background-color: #dc3545;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-left: 10px;
}

.process-button,
.cancel-button {
    padding: 12px 24px;
    font-size: 18px;
    border-radius: 5px;
    margin: 0 10px;
    min-width: 100px;
}

.button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.spinner {
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2px solid #3498db;
    width: 14px;
    height: 14px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    display: inline-block;
    margin-right: 8px;
}

.error-message {
    color: #dc3545;
    font-size: 14px;
    margin-top: 10px;
}
.sms-units {
    font-size: 14px;
    margin-top: 15px;
}


@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>