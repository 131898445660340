import { createRouter, createWebHistory } from "vue-router";
import { useToast } from "vue-toastification";
import store from '@/store';

// imports of Admin Authentication
import Auth from "../views/Auth/auth";
import login from "../views/Auth/login.vue";
import forgot_pwd from "../views/Auth/forgot_pwd";

// imports of Admin Dash Pages
import Main from "../views/Dash/main.vue";
import dashboard from "../views/Dash/dashboard/dashboard.vue";
import mainDashboard from "../views/Dash/dashboard/mainDashboard.vue";
import CreatedDashboards from "../views/Dash/dashboard/customize/CreatedDashboards.vue";
import testdashboard from "../views/Dash/dashboard/TestDashboard.vue";
import users from "../views/Dash/users/users.vue";
import reports from "../views/Dash/reports/reports.vue";
import survey from "../views/Dash/reports/my_survey.vue";
import forum from "../views/Dash/forum/forum.vue";
import projects from "../views/Dash/projects/projects.vue";
import new_projects from "../views/Dash/projects/new_projects.vue";
import uploads from "../views/Dash/uploads/uploads.vue";
import medications from "../views/Dash/medications/medications.vue";
import sms from "../views/Dash/bulk-sms/sms.vue";
import approve_sms from "../views/Dash/bulk-sms/approve-sms.vue";
import topup from "../views/Dash/bulk-sms/top-up.vue";

// Users Pages Start Point
import see_users from "../views/Dash/users/see_users.vue";
import create_accounts from "../views/Dash/users/create_accounts.vue";
import deactivated_accounts from "../views/Dash/users/deactivated_accounts.vue";
import community_members from "../views/Dash/users/tables/community_members.vue";
import community_health_workers from "../views/Dash/users/tables/community_health_workers.vue";
import educators from "../views/Dash/users/tables/educators.vue";
import Admins from "../views/Dash/users/tables/Admins.vue";

import HealthFacilityTeam from "../views/Dash/users/tables/health_facility_team.vue";
import HealthFacilityLead from "../views/Dash/users/tables/health_facility_lead.vue";


import health_facility_team_form from "../views/Dash/users/forms/educator_form.vue";
import community_health_workers_form from "../views/Dash/users/forms/community_health_worker_form.vue";
import health_facility_lead_form from "../views/Dash/users/forms/health_facility_lead_form.vue";
import admins_form from "../views/Dash/users/forms/admins_form.vue";
// Users Pages End Point

// Uploads Pages Start Point

import sessions from "../views/Dash/uploads/sessions.vue";
import hiv_session from "../views/Dash/uploads/sessions/hiv_session.vue";
import malaria_session from "../views/Dash/uploads/sessions/malaria_session.vue";
import tuberculosis_session from "../views/Dash/uploads/sessions/tuberculosis_session.vue";
import create_session from "../views/Dash/uploads/creste_session.vue";

import patient_tool_uploads from "../views/Dash/uploads/patient_tool/patient_tool.vue";
import patient_tool_uploads_session_details from "../views/Dash/uploads/patient_tool/session/session_details.vue";
import patient_tool_uploads_session_settings from "../views/Dash/uploads/patient_tool/session/session_settings.vue";
import patient_tool_uploads_session_files from "../views/Dash/uploads/patient_tool/session/session_files.vue";
import patient_tool_introduction from "../views/Dash/uploads/patient_tool/session/introduction.vue";
import patient_tool_introductory_questions from "../views/Dash/uploads/patient_tool/session/introductory_questions.vue";
import patient_tool_uploads_session_upload_files from "../views/Dash/uploads/patient_tool/session/session_upload_file.vue";
import patient_tool_add_new_questionnaire from "../views/Dash/uploads/patient_tool/session/add_new_questionnaire.vue";
import patient_tool_uploads_session from "../views/Dash/uploads/patient_tool/sessions.vue";
import patient_tool_uploads_create_session from "../views/Dash/uploads/patient_tool/create_sessions.vue";

// Health Facility Tool
import health_facility_tool_uploads from "../views/Dash/uploads/health_facility_tool/health_facility.vue";
import health_facility_tool_uploads_session from "../views/Dash/uploads/health_facility_tool/sessions.vue";
import health_facility_tool_uploads_create_session from "../views/Dash/uploads/health_facility_tool/create_sessions.vue";

import health_facility_tool_uploads_session_details from "../views/Dash/uploads/health_facility_tool/session/session_details.vue";
import health_facility_tool_uploads_session_settings from "../views/Dash/uploads/health_facility_tool/session/session_settings.vue";
import health_facility_tool_uploads_session_files from "../views/Dash/uploads/health_facility_tool/session/session_files.vue";
import health_facility_introduction from "../views/Dash/uploads/health_facility_tool/session/introduction.vue";
import health_facility_introductory_questions from "../views/Dash/uploads/health_facility_tool/session/introductory_questions.vue";
import health_facility_tool_uploads_session_upload_files from "../views/Dash/uploads/health_facility_tool/session/session_upload_file.vue";
import health_facility_tool_add_new_questionnaire from "../views/Dash/uploads/health_facility_tool/session/add_new_questionnaire.vue";


// service User
import service_user_survey_uploads from "../views/Dash/uploads/service_user_survey/service_user_survey.vue";
import service_user_survey_uploads_session from "../views/Dash/uploads/service_user_survey/sessions.vue";
import service_user_survey_uploads_create_session from "../views/Dash/uploads/service_user_survey/create_sessions.vue";

import service_user_survey_uploads_session_details from "../views/Dash/uploads/service_user_survey/session/session_details.vue";
import service_user_survey_uploads_session_settings from "../views/Dash/uploads/service_user_survey/session/session_settings.vue";
import service_user_survey_uploads_session_files from "../views/Dash/uploads/service_user_survey/session/session_files.vue";
import service_user_introduction from "../views/Dash/uploads/service_user_survey/session/introduction.vue";
import service_user_introductory_questions from "../views/Dash/uploads/service_user_survey/session/introductory_questions.vue";
import service_user_survey_uploads_session_upload_files from "../views/Dash/uploads/service_user_survey/session/session_upload_file.vue";

// facility team
import facility_team_uploads from "../views/Dash/uploads/facility_team/facility_team.vue";
import facility_team_uploads_session from "../views/Dash/uploads/facility_team/sessions.vue";
import facility_team_uploads_create_session from "../views/Dash/uploads/facility_team/create_sessions.vue";

import facility_team_uploads_session_details from "../views/Dash/uploads/facility_team/session/session_details.vue";
import facility_team_uploads_session_settings from "../views/Dash/uploads/facility_team/session/session_settings.vue";
import facility_team_uploads_session_files from "../views/Dash/uploads/facility_team/session/session_files.vue";
import facility_team_introduction from "../views/Dash/uploads/facility_team/session/introduction.vue";
import facility_team_introductory_questions from "../views/Dash/uploads/facility_team/session/introductory_questions.vue";
import facility_team_uploads_session_upload_files from "../views/Dash/uploads/facility_team/session/session_upload_file.vue";

// ayp survey
import ayp_survey_uploads from "../views/Dash/uploads/ayp_survey/ayp_survey.vue";
import ayp_survey_uploads_session from "../views/Dash/uploads/ayp_survey/sessions.vue";
import ayp_survey_uploads_create_session from "../views/Dash/uploads/ayp_survey/create_sessions.vue";
import ayp_survey_uploads_session_details from "../views/Dash/uploads/ayp_survey/session/session_details.vue";
import ayp_survey_uploads_session_settings from "../views/Dash/uploads/ayp_survey/session/session_settings.vue";
import ayp_survey_uploads_session_files from "../views/Dash/uploads/ayp_survey/session/session_files.vue";
import ayp_survey_introduction from "../views/Dash/uploads/ayp_survey/session/introduction.vue";
import ayp_survey_introductory_questions from "../views/Dash/uploads/ayp_survey/session/introductory_questions.vue";
import ayp_survey_uploads_session_upload_files from "../views/Dash/uploads/ayp_survey/session/session_upload_file.vue";

// kp survey
import kp_survey_uploads from "../views/Dash/uploads/kp_survey/kp_survey.vue";
import kp_survey_uploads_session from "../views/Dash/uploads/kp_survey/sessions.vue";
import kp_survey_uploads_create_session from "../views/Dash/uploads/kp_survey/create_sessions.vue";
import kp_survey_uploads_session_details from "../views/Dash/uploads/kp_survey/session/session_details.vue";
import kp_survey_uploads_session_settings from "../views/Dash/uploads/kp_survey/session/session_settings.vue";
import kp_survey_uploads_session_files from "../views/Dash/uploads/kp_survey/session/session_files.vue";
import kp_survey_introduction from "../views/Dash/uploads/kp_survey/session/introduction.vue";
import kp_survey_introductory_questions from "../views/Dash/uploads/kp_survey/session/introductory_questions.vue";
import kp_survey_uploads_session_upload_files from "../views/Dash/uploads/kp_survey/session/session_upload_file.vue";

// obsevation survey
import observation_survey_uploads from "../views/Dash/uploads/observation_survey/observation_survey.vue";
import observation_survey_uploads_session from "../views/Dash/uploads/observation_survey/sessions.vue";
import observation_survey_uploads_create_session from "../views/Dash/uploads/observation_survey/create_sessions.vue";
import observation_survey_uploads_session_details from "../views/Dash/uploads/observation_survey/session/session_details.vue";
import observation_survey_uploads_session_settings from "../views/Dash/uploads/observation_survey/session/session_settings.vue";
import observation_survey_uploads_session_files from "../views/Dash/uploads/observation_survey/session/session_files.vue";
import observation_survey_introduction from "../views/Dash/uploads/observation_survey/session/introduction.vue";
import observation_survey_introductory_questions from "../views/Dash/uploads/observation_survey/session/introductory_questions.vue";
import observation_survey_uploads_session_upload_files from "../views/Dash/uploads/observation_survey/session/session_upload_file.vue";

// import educator_uploads from "../views/Dash/uploads/educator/educator.vue";

import diagnosis_responses_uploads from "../views/Dash/uploads/diagnosis_responses/diagnosis_responses.vue";

// Community based tool
import community_based_tool_uploads from "../views/Dash/uploads/community_based_tool/standard_of_living.vue";
import community_based_tool_uploads_session from "../views/Dash/uploads/community_based_tool/sessions.vue";
import community_based_tool_uploads_create_session from "../views/Dash/uploads/community_based_tool/create_sessions.vue";

import community_based_tool_uploads_session_details from "../views/Dash/uploads/community_based_tool/session/session_details.vue";
import community_based_tool_uploads_session_settings from "../views/Dash/uploads/community_based_tool/session/session_settings.vue";
import community_based_tool_uploads_session_files from "../views/Dash/uploads/community_based_tool/session/session_files.vue";
import community_based_introduction from "../views/Dash/uploads/community_based_tool/session/introduction.vue";
import community_based_introductory_questions from "../views/Dash/uploads/community_based_tool/session/introductory_questions.vue";
import community_based_tool_uploads_session_upload_files from "../views/Dash/uploads/community_based_tool/session/session_upload_file.vue";
import community_based_tool_add_new_questionnaire from "../views/Dash/uploads/community_based_tool/session/add_new_questionnaire.vue";

// observation tool
import observation_uploads from "../views/Dash/uploads/observation_tool/health_tips.vue";
import observation_uploads_session from "../views/Dash/uploads/observation_tool/sessions.vue";
import observation_uploads_create_session from "../views/Dash/uploads/observation_tool/create_sessions.vue";

import observation_uploads_session_details from "../views/Dash/uploads/observation_tool/session/session_details.vue";
import observation_uploads_session_settings from "../views/Dash/uploads/observation_tool/session/session_settings.vue";
import observation_introduction from "../views/Dash/uploads/observation_tool/session/introduction.vue";
import observation_uploads_session_files from "../views/Dash/uploads/observation_tool/session/session_files.vue";
import observation_uploads_session_upload_files from "../views/Dash/uploads/observation_tool/session/session_upload_file.vue";
import observation_add_new_questionnaire from "../views/Dash/uploads/observation_tool/session/add_new_questionnaire.vue";

// Standards of living
import health_tips_uploads from "../views/Dash/uploads/health_tips/health_tips.vue";
import health_tips_uploads_session from "../views/Dash/uploads/health_tips/sessions.vue";
import health_tips_uploads_create_session from "../views/Dash/uploads/health_tips/create_sessions.vue";

import health_tips_uploads_session_details from "../views/Dash/uploads/health_tips/session/session_details.vue";
import health_tips_uploads_session_settings from "../views/Dash/uploads/health_tips/session/session_settings.vue";
import health_tips_uploads_session_files from "../views/Dash/uploads/health_tips/session/session_files.vue";
import health_tips_uploads_session_upload_files from "../views/Dash/uploads/health_tips/session/session_upload_file.vue";


// REPORTS Pages Start Point

import diagnosis from "../views/Dash/reports/diagnosis/diagnosis.vue";
import diagnosis_community_member from "../views/Dash/reports/diagnosis/community_member.vue";
import diagnosis_community_educator from "../views/Dash/reports/diagnosis/educator.vue";
import diagnosis_community_health_worker from "../views/Dash/reports/diagnosis/health_worker.vue";

import standards_of_living_reports from "../views/Dash/reports/standards_of_living/standards_of_living.vue";
import standards_of_living_educator from "../views/Dash/reports/standards_of_living/educator.vue";
import standards_of_living_health_worker from "../views/Dash/reports/standards_of_living/health_worker.vue";

//health facility team
import health_facility_team_reports from "../views/Dash/reports/health_facility_team/health_facility_team.vue";
import health_facility_tool from "../views/Dash/reports/health_facility_team/health_facility_tool.vue";
import patient_tool from "../views/Dash/reports/health_facility_team/patient_tool.vue";

//community based team
import community_based_team_reports from "../views/Dash/reports/community_based_team/community_based_team.vue";
import community_based_tool from "../views/Dash/reports/community_based_team/community_based_tool.vue";

//Health Facility Lead
import health_facility_lead_reports from "../views/Dash/reports/health_facility_lead/health_facility_lead.vue";
import service_provider_tool from "../views/Dash/reports/health_facility_lead/service_provider_tool.vue";

// REPORTS Pages End Point

const Toast = useToast();

const routes = [
  {
    path: "/",
    name: "Auth",
    component: Auth,
    children: [
      {
        path: "",
        name: "login",
        component: login,
      },
      {
        path: "reset",
        name: "forgot_pwd",
        component: forgot_pwd,
      },
    ],
  },

  {
    path: "/main",
    name: "Main",
    component: Main,
    meta: { requiresAuth: true },
    children: [
      {
        path: "dash",
        name: "dashboard",
        component: dashboard,
      },
      {
        path: "dashboard",
        name: "mainDashboard",
        component: mainDashboard,
      },
      {
        path: "dash/:id",
        name: "CreatedDashboards",
        component: CreatedDashboards,
        props: true,
      },
      {
        path: "testdash",
        name: "testdashboard",
        component: testdashboard,
      },
      {
        path: "users",
        name: "users",
        component: users,
        children: [
          {
            path: "list",
            name: "see_users",
            component: see_users,
            children: [
              {
                path: "members",
                name: "community_members",
                component: community_members,
              },
              {
                path: "health_facility_team",
                name: "health_facility_teams",
                component: HealthFacilityTeam,
              },
              {
                path: "educators",
                name: "educators",
                component: educators,
              },
              {
                path: "health_facility_lead",
                name: "health_facility_leads",
                component: HealthFacilityLead,
              },
              {
                path: "workers",
                name: "community_health_workers",
                component: community_health_workers,
              },
              {
                path: "admins",
                name: "admins",
                component: Admins,
              },
            ],
          },
          {
            path: "accounts",
            name: "create_accounts",
            component: create_accounts,
            children: [
              {
                path: "health-facility-team",
                name: "health_facility_team_form",
                component: health_facility_team_form,
              },
              {
                path: "admins",
                name: "admins_form",
                component: admins_form,
              },
              {
                path: "community-based",
                name: "community_health_worker_form",
                component: community_health_workers_form,
              },
              {
                path: "health-facility-lead",
                name: "health_facility_lead_form",
                component: health_facility_lead_form,
              },
            ],
          },
          {
            path: "deactivated",
            name: "deactivated_accounts",
            component: deactivated_accounts,
          },
        ],
      },
      {
        path: "forum",
        name: "forum",
        component: forum,
      },
      {
        path: "projects",
        name: "projects",
        component: projects,
      },
      {
        path: "new/projects",
        name: "new_projects",
        component: new_projects,
      },
      {
        path: "reports",
        name: "reports",
        component: reports,
        children: [
          {
            path: "diagnosis",
            name: "diagnosis",
            component: diagnosis,
            children: [
              {
                path: "member",
                name: "diagnosis_community_member",
                component: diagnosis_community_member,
              },
              {
                path: "educator",
                name: "diagnosis_community_educator",
                component: diagnosis_community_educator,
              },
              {
                path: "worker",
                name: "diagnosis_community_health_worker",
                component: diagnosis_community_health_worker,
              },
            ],
          },
          {
            path: "standards-of-living",
            name: "standards_of_living_reports",
            component: standards_of_living_reports,
            children: [
              {
                path: "educator",
                name: "standards_of_living_educator",
                component: standards_of_living_educator,
              },
              {
                path: "worker",
                name: "standards_of_living_health_worker",
                component: standards_of_living_health_worker,
              },
            ],
          },
          {
            path: "health-facility-team",
            name: "health_facility_team",
            component: health_facility_team_reports,
            children: [
              {
                path: "patient-tool",
                name: "patient_tool",
                component: patient_tool,
              },
            ],
          },
          {
            path: "community-based-team",
            name: "community_based_team",
            component: community_based_team_reports,
            children: [
              {
                path: "community-based-tool",
                name: "community_based_tool",
                component: community_based_tool,
              },
            ],
          },
          {
            path: "health-facility-lead",
            name: "health_facility_lead",
            component: health_facility_lead_reports,
            children: [
              {
                path: "health-facility-tool",
                name: "health_facility_tool",
                component: health_facility_tool,
              },
              {
                path: "service-provider-tool",
                name: "service_provider_tool",
                component: service_provider_tool,
              },
            ],
          },
        ],
      },
      {
        path: "/survey/:userId/:sessionId/:participantId",
        name: "survey",
        component: survey,
      },
      {
        path: "uploads",
        name: "uploads",
        component: uploads,
        children: [
          {
            path: "sessions",
            name: "sessions",
            component: sessions,
            children: [
              {
                path: "hiv",
                name: "hiv_sessions",
                component: hiv_session,
              },
              {
                path: "tb",
                name: "tb_sessions",
                component: tuberculosis_session,
              },
              {
                path: "malaria",
                name: "malaria_sessions",
                component: malaria_session,
              },
            ],
          },
          {
            path: "create",
            name: "create_session",
            component: create_session,
          },
          {
            path: "patient-tool",
            name: "patient_tool_uploads",
            component: patient_tool_uploads,
            children: [
              {
                path: "sessions",
                name: "patient_tool_uploads_session",
                component: patient_tool_uploads_session,
              },
              {
                path: "create",
                name: "patient_tool_uploads_create_session",
                component: patient_tool_uploads_create_session,
              },
            ],
          },
          {
            path: "patient-tool/sessions",
            name: "patient_tool_uploads_session_details",
            component: patient_tool_uploads_session_details,

            children: [
              {
                path: "introduction/:id",
                name: "patient_tool_introduction",
                component: patient_tool_introduction,
              },
              {
                path: "intro-file/:id",
                name: "patient_tool_introductory_questions",
                component: patient_tool_introductory_questions,
              },
              {
                path: "files/:id",
                name: "patient_tool_uploads_session_files",
                component: patient_tool_uploads_session_files,
              },
              {
                path: "add/:id",
                name: "patient_tool_uploads_session_upload_files",
                component: patient_tool_uploads_session_upload_files,
              },
              {
                path: "new/:id",
                name: "patient_tool_add_new_questionnaire",
                component: patient_tool_add_new_questionnaire,
              },
              {
                path: "settings/:id",
                name: "patient_tool_uploads_session_settings",
                component: patient_tool_uploads_session_settings,

              },
            ],
          },
          {
            path: "health-facility",
            name: "health_facility_tool_uploads",
            component: health_facility_tool_uploads,
            children: [
              {
                path: "sessions",
                name: "health_facility_tool_uploads_session",
                component: health_facility_tool_uploads_session,
              },
              {
                path: "create",
                name: "health_facility_tool_uploads_create_session",
                component: health_facility_tool_uploads_create_session,
              },
            ],
          },
          {
            path: "health-facility/sessions",
            name: "health_facility_tool_uploads_session_details",
            component: health_facility_tool_uploads_session_details,

            children: [
              {
                path: "files/:id",
                name: "health_facility_tool_uploads_session_files",
                component: health_facility_tool_uploads_session_files,
              },
              {
                path: "introduction/:id",
                name: "health_facility_introduction",
                component: health_facility_introduction,
              },
              {
                path: "intro-file/:id",
                name: "health_facility_introductory_questions",
                component: health_facility_introductory_questions,
              },
              {
                path: "add/:id",
                name: "health_facility_tool_uploads_session_upload_files",
                component:
                  health_facility_tool_uploads_session_upload_files,
              },
              {
                path: "new/:id",
                name: "health_facility_tool_add_new_questionnaire",
                component:
                  health_facility_tool_add_new_questionnaire,
              },
              {
                path: "settings/:id",
                name: "health_facility_tool_uploads_session_settings",
                component: health_facility_tool_uploads_session_settings,
              },
            ],
          },

          {
            path: "service-user-survey",
            name: "service_user_survey_uploads",
            component: service_user_survey_uploads,
            children: [
              {
                path: "sessions",
                name: "service_user_survey_uploads_session",
                component: service_user_survey_uploads_session,
              },
              {
                path: "create",
                name: "service_user_survey_uploads_create_session",
                component: service_user_survey_uploads_create_session,
              },
            ],
          },
          {
            path: "service-user-survey/sessions",
            name: "service_user_survey_uploads_session_details",
            component: service_user_survey_uploads_session_details,

            children: [
              {
                path: "files/:id",
                name: "service_user_survey_uploads_session_files",
                component: service_user_survey_uploads_session_files,
              },
              {
                path: "introduction/:id",
                name: "service_user_introduction",
                component: service_user_introduction,
              },
              {
                path: "intro-file/:id",
                name: "service_user_introductory_questions",
                component: service_user_introductory_questions,
              },
              {
                path: "add/:id",
                name: "service_user_survey_uploads_session_upload_files",
                component:
                  service_user_survey_uploads_session_upload_files,
              },
              {
                path: "settings/:id",
                name: "service_user_survey_uploads_session_settings",
                component: service_user_survey_uploads_session_settings,
              },
            ],
          },

          {
            path: "facility-team",
            name: "facility_team_uploads",
            component: facility_team_uploads,
            children: [
              {
                path: "sessions",
                name: "facility_team_uploads_session",
                component: facility_team_uploads_session,
              },
              {
                path: "create",
                name: "facility_team_uploads_create_session",
                component: facility_team_uploads_create_session,
              },
            ],
          },
          {
            path: "facility-team/sessions",
            name: "facility_team_uploads_session_details",
            component: facility_team_uploads_session_details,

            children: [
              {
                path: "files/:id",
                name: "facility_team_uploads_session_files",
                component: facility_team_uploads_session_files,
              },
              {
                path: "introduction/:id",
                name: "facility_team_introduction",
                component: facility_team_introduction,
              },
              {
                path: "intro-file/:id",
                name: "facility_team_introductory_questions",
                component: facility_team_introductory_questions,
              },
              {
                path: "add/:id",
                name: "facility_team_uploads_session_upload_files",
                component: facility_team_uploads_session_upload_files,
              },
              {
                path: "settings/:id",
                name: "facility_team_uploads_session_settings",
                component: facility_team_uploads_session_settings,
              },
            ],
          },
          {
            path: "ayp-survey",
            name: "ayp_survey_uploads",
            component: ayp_survey_uploads,
            children: [
              {
                path: "sessions",
                name: "ayp_survey_uploads_session",
                component: ayp_survey_uploads_session,
              },
              {
                path: "create",
                name: "ayp_survey_uploads_create_session",
                component: ayp_survey_uploads_create_session,
              },
            ],
          },
          {
            path: "ayp-survey/sessions",
            name: "ayp_survey_uploads_session_details",
            component: ayp_survey_uploads_session_details,

            children: [
              {
                path: "files/:id",
                name: "ayp_survey_uploads_session_files",
                component: ayp_survey_uploads_session_files,
              },
              {
                path: "introduction/:id",
                name: "ayp_survey_introduction",
                component: ayp_survey_introduction,
              },
              {
                path: "intro-file/:id",
                name: "ayp_survey_introductory_questions",
                component: ayp_survey_introductory_questions,
              },
              {
                path: "add/:id",
                name: "ayp_survey_uploads_session_upload_files",
                component: ayp_survey_uploads_session_upload_files,
              },
              {
                path: "settings/:id",
                name: "ayp_survey_uploads_session_settings",
                component: ayp_survey_uploads_session_settings,
              },
            ],
          },

          {
            path: "kp-survey",
            name: "kp_survey_uploads",
            component: kp_survey_uploads,
            children: [
              {
                path: "sessions",
                name: "kp_survey_uploads_session",
                component: kp_survey_uploads_session,
              },
              {
                path: "create",
                name: "kp_survey_uploads_create_session",
                component: kp_survey_uploads_create_session,
              },
            ],
          },
          {
            path: "kp-survey/sessions",
            name: "kp_survey_uploads_session_details",
            component: kp_survey_uploads_session_details,

            children: [
              {
                path: "files/:id",
                name: "kp_survey_uploads_session_files",
                component: kp_survey_uploads_session_files,
              },
              {
                path: "introduction/:id",
                name: "kp_survey_introduction",
                component: kp_survey_introduction,
              },
              {
                path: "intro-file/:id",
                name: "kp_survey_introductory_questions",
                component: kp_survey_introductory_questions,
              },
              {
                path: "add/:id",
                name: "kp_survey_uploads_session_upload_files",
                component: kp_survey_uploads_session_upload_files,
              },
              {
                path: "settings/:id",
                name: "kp_survey_uploads_session_settings",
                component: kp_survey_uploads_session_settings,
              },
            ],
          },

          {
            path: "observation-survey",
            name: "observation_survey_uploads",
            component: observation_survey_uploads,
            children: [
              {
                path: "sessions",
                name: "observation_survey_uploads_session",
                component: observation_survey_uploads_session,
              },
              {
                path: "create",
                name: "observation_survey_uploads_create_session",
                component: observation_survey_uploads_create_session,
              },
            ],
          },
          {
            path: "observation-survey/sessions",
            name: "observation_survey_uploads_session_details",
            component: observation_survey_uploads_session_details,

            children: [
              {
                path: "files/:id",
                name: "observation_survey_uploads_session_files",
                component: observation_survey_uploads_session_files,
              },
              {
                path: "introduction/:id",
                name: "observation_survey_introduction",
                component: observation_survey_introduction,
              },
              {
                path: "intro-file/:id",
                name: "observation_survey_introductory_questions",
                component: observation_survey_introductory_questions,
              },
              {
                path: "add/:id",
                name: "observation_survey_uploads_session_upload_files",
                component: observation_survey_uploads_session_upload_files,
              },
              {
                path: "settings/:id",
                name: "observation_survey_uploads_session_settings",
                component: observation_survey_uploads_session_settings,
              },
            ],
          },

          {
            path: "community-based-tool",
            name: "community_based_tool_uploads",
            component: community_based_tool_uploads,
            children: [
              {
                path: "sessions",
                name: "community_based_tool_uploads_session",
                component: community_based_tool_uploads_session,
              },
              {
                path: "create",
                name: "community_based_tool_uploads_create_session",
                component: community_based_tool_uploads_create_session,
              },
            ],
          },

          {
            path: "community_based_tool/sessions",
            name: "community_based_tool_uploads_session_details",
            component: community_based_tool_uploads_session_details,

            children: [
              {
                path: "files/:id",
                name: "community_based_tool_uploads_session_files",
                component: community_based_tool_uploads_session_files,
              },
              {
                path: "introduction/:id",
                name: "community_based_introduction",
                component: community_based_introduction,
              },
              {
                path: "intro-file/:id",
                name: "community_based_introductory_questions",
                component: community_based_introductory_questions,
              },
              {
                path: "add/:id",
                name: "community_based_tool_uploads_session_upload_files",
                component: community_based_tool_uploads_session_upload_files,
              },
              {
                path: "new/:id",
                name: "community_based_tool_add_new_questionnaire",
                component: community_based_tool_add_new_questionnaire,
              },
              {
                path: "settings/:id",
                name: "community_based_tool_uploads_session_settings",
                component: community_based_tool_uploads_session_settings,
              },
            ],
          },
          {
            path: "service-provider",
            name: "health_tips_uploads",
            component: health_tips_uploads,
            children: [
              {
                path: "sessions",
                name: "health_tips_uploads_session",
                component: health_tips_uploads_session,
              },
              {
                path: "create",
                name: "health_tips_uploads_create_session",
                component: health_tips_uploads_create_session,
              },
            ],
          },

          {
            path: "service-provider/sessions",
            name: "health_tips_session_details",
            component: health_tips_uploads_session_details,

            children: [
              {
                path: "files/:id",
                name: "health_tips_uploads_session_files",
                component: health_tips_uploads_session_files,
              },
              {
                path: "add/:id",
                name: "health_tips_uploads_session_upload_files",
                component: health_tips_uploads_session_upload_files,
              },
              {
                path: "settings/:id",
                name: "health_tips_uploads_session_settings",
                component: health_tips_uploads_session_settings,
              },
            ],
          },

          {
            path: "observation-tool",
            name: "observation_uploads",
            component: observation_uploads,
            children: [
              {
                path: "sessions",
                name: "observation_uploads_session",
                component: observation_uploads_session,
              },
              {
                path: "create",
                name: "observation_uploads_create_session",
                component: observation_uploads_create_session,
              },
            ],
          },

          {
            path: "observation-tool/sessions",
            name: "observation_session_details",
            component: observation_uploads_session_details,

            children: [
              {
                path: "introduction/:id",
                name: "observation_introduction",
                component: observation_introduction,
              },
              {
                path: "files/:id",
                name: "observation_uploads_session_files",
                component: observation_uploads_session_files,
              },
              {
                path: "add/:id",
                name: "observation_uploads_session_upload_files",
                component: observation_uploads_session_upload_files,
              },
              {
                path: "new/:id",
                name: "observation_add_new_questionnaire",
                component: observation_add_new_questionnaire,
              },
              {
                path: "settings/:id",
                name: "observation_uploads_session_settings",
                component: observation_uploads_session_settings,
              },
            ],
          },

          {
            path: "responses",
            name: "diagnosis_responses_uploads",
            component: diagnosis_responses_uploads,
          },
        ],
      },
      {
        path: "sms",
        name: "sms",
        component: sms,
      },
      {
        path: "approve-sms",
        name: "approve_sms",
        component: approve_sms,
      },
      {
        path: "top-up",
        name: "topup",
        component: topup,
      },
      {
        path: "medications",
        name: "medications",
        component: medications,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // const isAuthenticated = localStorage.getItem("isLoggedIn") === "true";
  const isAuthenticated = localStorage.getItem("token") !== null;
  if (to.matched.some((record) => record.meta.requiresAuth) && !isAuthenticated) {
    Toast.error("Please first login!");
    next({
      name: "login",
      query: { redirect: to.fullPath },
    });
  } else if (to.matched.length === 0) {
    Toast.error("Error 404: Page doesn't Exist");
    next({ ...from, replace: true });
  } else if (isAuthenticated && to.name === "login") {
    next({ name: "dashboard" });
  } else {
    next();
  }
});

export default router;
