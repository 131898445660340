<template>
    <div class="card">
        <div class="card-header">
            <h3>Q&A Analysis</h3>
        </div>
        <div class="content">
            <div class="filter-container">
                <div class="container">
                    <select v-model="selectedProvince" @change="fetchDistricts">
                        <option disabled value="">Select Province</option>
                        <!-- <option v-for="province in provinces" :key="province.id" :value="province.id">{{ province.name
                            }}
                        </option> -->
                        <option value="1">Kigali City</option>
                    </select>
                </div>
                <div class="container">
                    <select v-model="selectedDistrict" @change="fetchSites">
                        <option disabled value="">Select District</option>
                        <option v-for="district in districts" :key="district.id" :value="district.id">
                            {{ district.name }}
                        </option>
                    </select>
                </div>
                <div class="container">
                    <select v-model="selectedTool" @change="fetchQuestions" class="styled-select"
                        v-if="projectId === 1">
                        <option disabled value="">Select Tool</option>
                        <option value="1" v-if="hasPermission('tools', 'patient_tool')">Patient Tool</option>
                        <option value="2" v-if="hasPermission('tools', 'health_facility_tool')">Health Facility</option>
                        <option value="3" v-if="hasPermission('tools', 'community_based_tool')">Community Based</option>
                        <option value="4" v-if="hasPermission('tools', 'observation_tool')">Observation Tool</option>

                    </select>
                    <select v-model="selectedTool" @change="fetchQuestions" class="styled-select" v-else>
                        <option disabled value="">Select Tool</option>
                        <option value="5" v-if="hasPermission('tools', 'service_user_survey')">Service User</option>
                        <option value="6" v-if="hasPermission('tools', 'facility_manager_survey')">Facility Manager
                            Survey</option>
                        <option value="7" v-if="hasPermission('tools', 'ayp_survey')">AYP Survey</option>
                        <option value="8" v-if="hasPermission('tools', 'kp_survey')">KP Survey</option>
                        <option value="9" v-if="hasPermission('tools', 'observation_survey')">Observation Survey
                        </option>
                    </select>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
                            stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
                <div class="container">
                    <select v-model="selectedCondition" @change="fetchQuestions" class="styled-select"
                        v-if="projectId === 1">
                        <option disabled value="">Select Condition</option>
                        <option value="1" v-if="hasPermission('conditions', 'hiv')">HIV</option>
                        <option value="2" v-if="hasPermission('conditions', 'malaria')">Malaria</option>
                        <option value="3" v-if="hasPermission('conditions', 'tuberculosis')">Tuberculosis</option>
                    </select>
                    <select v-model="selectedCondition" @change="fetchQuestions" class="styled-select" v-else>
                        <option disabled value="">Select Condition</option>
                        <option value="4" v-if="hasPermission('conditions', 'hiv')">HIV</option>
                    </select>
                </div>
                <!-- <div class="container">
                    <Multiselect v-model="selectedQuestion" :options="questions" track-by="id" label="eng_question"
                        placeholder="Search or select a question" searchable close-on-select :allowEmpty="true"
                        @input="updateResponses" />
                </div> -->

                <div class="container" v-if="selectedLanguage === 1">
                    <select class="styled-select" v-model="selectedQuestion" @change="updateResponses"
                        :disabled="loading || !questions.length">
                        <option disabled value="">Select Question</option>
                        <option v-for="question in questions" :key="question.id" :value="question">
                            {{ question.eng_question }}
                        </option>
                    </select>
                </div>
                <div class="container" v-else>
                    <select class="styled-select" v-model="selectedQuestion" @change="updateResponses"
                        :disabled="loading || !questions.length">
                        <option disabled value="">Select Question</option>
                        <option v-for="question in questions" :key="question.id" :value="question">
                            {{ question.question }}
                        </option>
                    </select>
                </div>
                <div class="container" v-if="selectedLanguage === 1">
                    <select v-model="selectedResponse" @change="fetchGraphData" class="styled-select">
                        <option disabled value="">Select Response</option>
                        <option v-for="response in selectedQuestion?.response_options || []" :key="response.id"
                            :value="response.id">
                            {{ response.eng_response }}
                        </option>
                    </select>
                </div>
                <div class="container" v-else>
                    <select v-model="selectedResponse" @change="fetchGraphData" class="styled-select">
                        <option disabled value="">Select Response</option>
                        <option v-for="response in selectedQuestion?.response_options || []" :key="response.id"
                            :value="response.id">
                            {{ response.response }}
                        </option>
                    </select>
                </div>


            </div>

        </div><br>
        <!-- Chart and Table Layout -->
        <div class="chart-table-container" ref="chartTableContainer">
            <!-- Chart Section -->
            <div class="chart-section">
                <h4>Site Distribution</h4>
                <div class="chart-container" ref="chartContainer"></div>
            </div>

            <!-- Table Section -->
            <div class="table-section">
                <h4>Data Summary</h4>
                <p v-if="selectedLanguage === 1">
                    <strong>Question:</strong> {{ selectedQuestion?.eng_question || 'N/A' }}<br />
                    <strong>Response:</strong> {{ selectedResponseText }}

                </p>
                <p v-else>
                    <strong>Question:</strong> {{ selectedQuestion?.question || 'N/A' }}<br />
                    <strong>Response:</strong> {{ selectedResponseText }}

                </p>
                <table>
                    <thead>
                        <tr>
                            <th>Sites</th>
                            <th>Count</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(center, index) in chartData" :key="index">
                            <td>{{ center.name }}</td>
                            <td>{{ center.value }}</td>
                        </tr>
                        <tr>
                            <td><strong>Total</strong></td>
                            <td><strong>{{ totalCount }}</strong></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="download-buttons">
            <button @click="downloadAsJPEG"><i class="fa fa-download"></i> Download as JPEG</button>
            <button @click="downloadAsPDF"><i class="fa fa-download"></i> Download as PDF</button>
        </div>

    </div>
</template>


<script>
import * as echarts from "echarts";
import axios from "@/helpers/axios.js";
import { mapGetters, mapState } from "vuex";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";


export default {
    data() {
        return {
            provinces: [],
            districts: [],
            sites: [], // Store health centers
            questions: [],
            selectedTool: "",
            selectedProvince: "",
            selectedDistrict: "",
            selectedCondition: "",
            selectedQuestion: "",
            selectedResponse: "",
            chartInstance: null,
            loading: false,
            error: null,
            chartData: [],
            totalCount: 0,
        };
    },
    components: {
        Multiselect,
    },
    mounted() {
        this.fetchProvinces();
        this.$nextTick(() => {
            this.initChart();
        });
        window.addEventListener("resize", this.resizeChart);
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.resizeChart);
        if (this.chartInstance) this.chartInstance.dispose();
    },
    computed: {
        ...mapGetters({
            hasPermission: 'hasPermission',
            selectedLanguage: 'selectedLanguage',
        }),
        ...mapState({
            projectId: (state) => state.selectedProjectId,
        }),
        selectedResponseText() {
            const response = this.selectedQuestion?.response_options?.find(
                (option) => option.id === this.selectedResponse
            );
            if (this.selectedLanguage === 1) {
                return response ? response.eng_response : "N/A";
            }
            else {
                return response ? response.response : "N/A";
            }
        },
    },
    methods: {
        async fetchProvinces() {
            try {
                const response = await axios.get("/province/1");
                this.provinces = response.data.data;
            } catch (error) {
                console.error("Error fetching provinces:", error);
            }
        },
        async fetchDistricts() {
            if (!this.selectedProvince) return;
            try {
                const response = await axios.get(`/districts/${this.selectedProvince}`);
                this.districts = response.data.data;
            } catch (error) {
                console.error("Error fetching districts:", error);
            }
        },
        async fetchSites() {
            if (!this.selectedDistrict) return;
            try {
                const response = await axios.get(`/district/health-centers?district_id=${this.selectedDistrict}`);
                this.sites = response.data.data.map((site) => site.id); // Save site IDs
                console.log(this.sites);
            } catch (error) {
                console.error("Error fetching health centers:", error);
            }
        },
        async fetchQuestions() {
            try {
                const requestData = {
                    tool_id: [Number(this.selectedTool)],
                    condition_id: [Number(this.selectedCondition)],
                    district_id: [this.selectedDistrict],
                };
                const endpoint =
                    this.projectId === 1
                        ? "/customized-questions"
                        : "/customized-questions";
                const response = await axios.post(endpoint, requestData);
                if (response.data.status) {
                    this.questions = response.data.data;
                }
            } catch (error) {
                console.error("Error fetching questions:", error);
            }
        },
        async fetchGraphData() {
            if (!this.selectedQuestion || !this.selectedResponse || !this.sites.length) return;
            try {
                const payload = {
                    project_id: this.projectId,
                    tool_id: [this.selectedTool],
                    condition_id: [this.selectedCondition],
                    question_id: [this.selectedQuestion.id],
                    response_id: [this.selectedResponse],
                    language_id: 2,
                    site_id: this.sites,
                };
                const response = await axios.post("/questionnaire-analysis", payload);
                if (response.data.status && response.data.data.length > 0) {
                    const healthCenters = response.data.data[0]?.responses[0]?.health_centers || [];
                    this.chartData = healthCenters.map((center) => ({
                        name: center.health_center,
                        value: center.count,
                    }));
                    this.updateChart(this.chartData);
                    this.totalCount = healthCenters.reduce((sum, center) => sum + center.count, 0);
                } else {
                    console.error("No data available for the selected options.");
                    this.chartData = [];
                    this.totalCount = 0;
                    this.updateChart([]);
                }
            } catch (error) {
                console.error("Error fetching graph data:", error);
            }
        },
        initChart() {
            if (this.$refs.chartContainer) {
                this.chartInstance = echarts.init(this.$refs.chartContainer, null, {
                    width: this.$refs.chartContainer.offsetWidth,
                    height: this.$refs.chartContainer.offsetHeight,
                });
            }
        },
        updateChart(healthCenters) {
            if (!this.chartInstance) {
                console.error("Chart instance is not initialized.");
                return;
            }

            const option = {
                backgroundColor: 'transparent',
                tooltip: {
                    trigger: "item",
                    formatter: "{b}: {c} ({d}%)",
                },
                series: [
                    {
                        name: "Responses",
                        type: "pie",
                        radius: ["45%", "75%"],
                        data: healthCenters,
                        label: {
                            show: true,
                            position: "outside",
                            formatter: "{b}: {c}",
                            fontSize: 12,
                            fontWeight: "bold",
                        },
                        labelLine: {
                            show: true,
                            smooth: 0.2,
                            length: 10, 
                            length2: 15, 
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    },
                ],
            };

            if (healthCenters.length === 0) {
                option.series[0].data = [{ value: 0, name: "No Data" }];
            }

            this.chartInstance.setOption(option);
        },
        resizeChart() {
            if (this.chartInstance) {
                this.chartInstance.resize();
            }
        },
        async downloadAsJPEG() {
            const element = this.$refs.chartTableContainer;
            if (!element) {
                console.error("Chart and table container not found.");
                return;
            }
            const canvas = await html2canvas(element);
            const link = document.createElement("a");
            link.href = canvas.toDataURL("image/jpeg");
            link.download = "chart-and-table.jpg";
            link.click();
        },
        async downloadAsPDF() {
            const element = this.$refs.chartTableContainer;
            if (!element) {
                console.error("Chart and table container not found.");
                return;
            }
            const canvas = await html2canvas(element);
            const imgData = canvas.toDataURL("image/jpeg");
            const pdf = new jsPDF("p", "mm", "a4");
            const imgWidth = 210; // A4 width in mm
            const pageHeight = 297; // A4 height in mm
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;

            let position = 0;
            while (heightLeft > 0) {
                pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
                if (heightLeft > 0) {
                    position -= pageHeight;
                    pdf.addPage();
                }
            }

            pdf.save("chart-and-table.pdf");
        },
    },
};
</script>




<style scoped>
.card {
    width: 100%;
    max-width: 1600px;
    height: auto;
    box-shadow: 0 0 14px rgb(206, 201, 201);
    background-color: white;
    border-radius: 15px;
    padding: 20px;
    margin-bottom: 20px;
}

.card-header {
    width: 100%;
    margin-bottom: 20px;

    .title-heading {
        text-align: center
    }

}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
}

.filter-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
    width: 100%;
}

.container {
    flex: 1 1 200px;
    max-width: 250px;
    display: flex;
    align-items: center;
}

.styled-select,
select {
    width: 100%;
    height: 40px;
    font-size: 14px;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    box-sizing: border-box;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
}

.styled-select:focus,
select:focus {
    border-color: #39325a;
    outline: none;
}

.styled-select option,
select option {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.no-data-message {
    text-align: center;
    color: #666;
    padding: 20px;
    font-size: 16px;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

li {
    padding: 5px 0;
    display: flex;
    align-items: center;
}

li span {
    margin-right: 10px;
}

.bar-container {
    flex-grow: 1;
    height: 20px;
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
}

.bar {
    height: 100%;
    background-color: #4caf50;
    border-radius: 5px;
    position: absolute;
    left: 0;
    top: 0;
}

.toggle-btn {
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 1;
}

.toggle-btn.expanded {
    background-color: #c8392e;
}


select {
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    background-color: transparent;

}

.chart-table-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;
    width: 100%;
    flex-wrap: nowrap;
}

.chart-section,
.table-section {
    flex: 0 1 50%;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.chart-container {
    width: 100%;
    height: 400px;
    background-color: transparent;
}

.table-section h4,
.chart-section h4 {
    margin-bottom: 15px;
    font-size: 18px;
    color: #333333;
}

.table-section p {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 1.5;
    color: #333333;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

thead {
    background-color: #f4f4f4;
    color: #333333;
}

th,
td {
    text-align: left;
    padding: 10px;
    border: 1px solid #ccc;
}

tr:nth-child(even) {
    background-color: #f9f9f9;
}

tr:last-child td {
    font-weight: bold;
    background-color: #f4f4f4;
}

.download-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.download-buttons button {
    margin: 0 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #003c88;
    color: #fff;
    font-size: 15px;
}

.download-buttons button:hover {
    background-color: #1956a7;
}
</style>