<template>
    <div class="card">
        <div class="card-header">
            <h3 class="title-heading">All Question Analysis</h3>
        </div>
        <div class="content">
            <div class="filter-container">
                <div class="container">
                    <select v-model="selectedTool" @change="fetchData" class="styled-select" v-if="projectId === 1">
                        <option disabled value="">Select Tool</option>
                        <option value="1" v-if="hasPermission('tools', 'patient_tool')">Patient Tool</option>
                        <option value="2" v-if="hasPermission('tools', 'health_facility_tool')">Health Facility</option>
                        <option value="3" v-if="hasPermission('tools', 'community_based_tool')">Community Based</option>
                        <option value="4" v-if="hasPermission('tools', 'observation_tool')">Observation Tool</option>

                    </select>
                    <select v-model="selectedTool" @change="fetchData" class="styled-select" v-else>
                        <option disabled value="">Select Tool</option>
                        <option value="5" v-if="hasPermission('tools', 'service_user_survey')">Service User</option>
                        <option value="6" v-if="hasPermission('tools', 'facility_manager_survey')">Facility Manager Survey</option>
                        <option value="7" v-if="hasPermission('tools', 'ayp_survey')">AYP Survey</option>
                        <option value="8" v-if="hasPermission('tools', 'kp_survey')">KP Survey</option>
                        <option value="9" v-if="hasPermission('tools', 'observation_survey')">Observation Survey</option>
                    </select>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
                            stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                </div>

                <div class="container">
                    <select v-model="selectedCondition" @change="fetchData" class="styled-select" v-if="projectId === 1">
                        <option disabled value="">Select Condition</option>
                        <option value="1" v-if="hasPermission('conditions', 'hiv')">HIV</option>
                        <option value="2" v-if="hasPermission('conditions', 'malaria')">Malaria</option>
                        <option value="3" v-if="hasPermission('conditions', 'tuberculosis')">Tuberculosis</option>
                    </select>
                    <select v-model="selectedCondition" @change="fetchData" class="styled-select" v-else>
                        <option disabled value="">Select Condition</option>
                        <option value="4" v-if="hasPermission('conditions', 'hiv')">HIV</option>
                    </select>

                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
                            stroke-linecap="round" stroke-linejoin="round" />
                    </svg>


                </div>

                <div class="container">
                    <select v-model="selectedProvince" @change="fetchDistricts" class="styled-select">
                        <option disabled value="">Select Province</option>
                        <option v-for="province in provinces" :key="province.id" :value="province.id">{{ province.name
                            }}
                        </option>
                    </select>


                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
                            stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>

                <div class="container">
                    <select v-model="selectedDistrict" @change="fetchData" class="styled-select">
                        <option disabled value="">Select District</option>
                        <option v-for="district in districts" :key="district.id" :value="district.id">{{ district.name
                            }}
                        </option>
                    </select>


                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
                            stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                </div>
            </div>
            <div class="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>+</th>
                            <th>Questions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="questions.length === 0">
                            <tr>
                                <td colspan="2" class="no-data-message">To view the question analysis, please select
                                    options or filters from the dropdowns above.</td>
                            </tr>
                        </template>
                        <template v-else v-for="question in questions" :key="question.id">
                            <tr>
                                <td @click="toggleExpand(question.id)">
                                    <button class="toggle-btn" :class="{ 'expanded': question.expanded }">{{
                                        question.expanded ? '-' : '+' }}</button>
                                </td>
                                <td v-if="selectedLanguage === 1">{{ question.eng_question || question.question }}</td>
                                <td v-else>{{ question.question }}</td>
                            </tr>
                            <tr v-if="question.expanded">
                                <td colspan="2">
                                    <ul>
                                        <li v-for="response in question.responses" :key="response.response_option_id"
                                            class="response-item">
                                            <span v-if="selectedLanguage === 1">{{ response.eng_response || response.response }}: </span>
                                            <span v-else>{{ response.response }}: </span>
                                            <span><b>{{ response.count }}</b></span>
                                            <div class="bar-container">
                                                <div class="bar" :style="{ width: `${getBarWidth(response.count)}%` }">
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>


<script>
import axios from '@/helpers/axios.js';
import { mapGetters, mapState } from "vuex";

export default {
    data() {
        return {
            provinces: [],
            districts: [],
            selectedTool: '',
            selectedCondition: '',
            selectedProvince: '',
            selectedDistrict: '',
            questions: [],
            maxCount: 0,
        };
    },
    computed: {
        ...mapGetters({
            hasPermission: 'hasPermission',
            selectedLanguage: 'selectedLanguage',
        }),
        ...mapState({
            projectId: (state) => state.selectedProjectId, // Project ID from Vuex
        }),
    },
    methods: {
        async fetchProvinces() {
            const response = await axios.get('/province');
            this.provinces = response.data.data;
        },
        async fetchDistricts() {
            const response = await axios.get(`/districts/${this.selectedProvince}`);
            this.districts = response.data.data;
            this.fetchData(); 
        },
        async fetchData() {
            const response = await axios.get('/questions-with-answers', {
                params: {
                    tool_id: this.selectedTool,
                    province_id: this.selectedProvince,
                    district_id: this.selectedDistrict,
                    condition_id: this.selectedCondition,
                },
            });
            const questions = response.data.data.map(question => ({
                ...question,
                expanded: false,
            }));
            this.questions = questions;
            this.maxCount = Math.max(...questions.flatMap(q => q.responses.map(r => r.count))); 
        },
        toggleExpand(questionId) {
            const question = this.questions.find(q => q.id === questionId);
            if (question) {
                question.expanded = !question.expanded;
            }
        },
        getBarWidth(count) {
            // Calculate bar width as a percentage based on the maximum count
            return (count / this.maxCount) * 100;
        },
    },
    mounted() {
        this.fetchProvinces();
    },
};
</script>



<style scoped>
.card {
    width: 100%;
    height: auto;
    box-shadow: 0 0 14px rgb(206, 201, 201);
    background-color: white;
    border-radius: 15px;
    padding: 20px;
    margin-bottom: 20px;
}

.card-header {
    width: 100%;
    margin-bottom: 20px;

    .title-heading {
        text-align: center
    }

}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
}

.filter-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.styled-select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    font-size: 14px;
    color: #333;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

}

.styled-select:focus {
    border-color: #39325a;
    outline: 2px solid #ccc;
    outline: none;

}

.table-container {
    width: 100%;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
}

th {
    background-color: #f4f4f4;
}

tbody tr:hover {
    background-color: #f9f9f9;
}

.no-data-message {
    text-align: center;
    color: #666;
    padding: 20px;
    font-size: 16px;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

li {
    padding: 5px 0;
    display: flex;
    align-items: center;
}

li span {
    margin-right: 10px;
}

.bar-container {
    flex-grow: 1;
    height: 20px;
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
}

.bar {
    height: 100%;
    background-color: #4caf50;
    border-radius: 5px;
    position: absolute;
    left: 0;
    top: 0;
}

.toggle-btn {
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 1;
}

.toggle-btn.expanded {
    background-color: #c8392e;
}


.container {
    position: relative;
    width: 100%;



    svg {
        background-color: white;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-50%, -50%);
        z-index: 1;
    }
}

select {
    min-width: 245px;
    height: 40px;
    padding-left: 15px;
    font-size: 16px;
    border: 1.9px solid lightgray;
    border-radius: 4px;
    color: #39325a;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    background-color: transparent;

}
</style>
