<template>
  <div class="card">
    <div class="card-header">
      <h3>Conditions Counts Per Location and Tool</h3>
    </div>
    <div class="content">
      <div class="chart-container" ref="chartContainer"></div>
      <div class="dropdown-container">
        <div class="container">
          <select v-model="selectedTool" @change="fetchChartData">
            <option disabled value="">Select Tool</option>
            <option value="1" v-if="hasPermission('tools', 'patient_tool')">Patient Tool</option>
            <option value="2" v-if="hasPermission('tools', 'health_facility_tool')">Health Facility</option>
            <option value="3" v-if="hasPermission('tools', 'community_based_tool')">Community Based</option>
            <option value="4" v-if="hasPermission('tools', 'observation_tool')">Observation Tool</option>
          </select>
        </div>
        <div class="container">
          <select v-model="selectedProvince" @change="fetchProvinceAndDistrictData">
            <option disabled value="">Select Province</option>
            <option v-for="province in provinces" :key="province.id" :value="province.id">{{ province.name }}</option>
          </select>
        </div>
        <div class="container">
          <select v-model="selectedDistrict" @change="fetchChartData">
            <option disabled value="">Select District</option>
            <option v-for="district in districts" :key="district.id" :value="district.id">{{ district.name }}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import axios from "@/helpers/axios.js";
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      provinces: [],
      districts: [],
      selectedTool: "",
      selectedProvince: "",
      selectedDistrict: "",
      chartInstance: null,
      loading: true,
      totalResponses: 35,
      defaultData: this.calculateDefaultData(30),
    };
  },
  mounted() {
    this.initChart(); // Initialize the chart with an empty placeholder
    this.fetchProvinces(); // Fetch provinces on load

    // Load initial data after a 3-second delay
    setTimeout(() => {
      this.loading = false;
      this.updateChart(this.defaultData);
    }, 3000);

    window.addEventListener("resize", this.resizeChart);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resizeChart);
    if (this.chartInstance) {
      this.chartInstance.dispose();
    }
  },
  computed: {
    ...mapGetters({
      hasPermission: 'hasPermission'
    }),
  },
  methods: {
    calculateDefaultData(totalResponses) {
      const hivPercentage = 0.70;
      const malariaPercentage = 0.20;
      const tbPercentage = 0.10;

      let hivCount = Math.floor(totalResponses * hivPercentage);
      let malariaCount = Math.floor(totalResponses * malariaPercentage);
      let tbCount = Math.floor(totalResponses * tbPercentage);

      let remaining = totalResponses - (hivCount + malariaCount + tbCount);

      hivCount += remaining;

      return {
        HIV: hivCount,
        Malaria: malariaCount,
        Tuberculosis: tbCount,
      };
    },
    initChart() {
      this.chartInstance = echarts.init(this.$refs.chartContainer);

      // Initial empty chart to act as a placeholder
      const placeholderOption = {
        series: [
          {
            name: 'Conditions',
            type: 'pie',
            radius: ['45%', '75%'],
            data: [
              { value: 1, name: 'Loading...', itemStyle: { color: 'rgba(200, 200, 200, 0.3)' } }
            ],
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
          },
        ],
      };
      this.chartInstance.setOption(placeholderOption);
    },
    updateChart(percentages) {
      const option = {
        tooltip: {
          trigger: 'item',
          formatter: '{b}: {c} ({d}%)',
        },
        legend: {
          top: '5%',
          left: 'center',
        },
        series: [
          {
            name: 'Conditions',
            type: 'pie',
            radius: ['45%', '75%'],
            avoidLabelOverlap: false,
            animationType: 'expansion',
            startAngle: 0,
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: percentages['HIV'], name: 'HIV', itemStyle: { color: 'rgba(255, 0, 0, 0.7)' } },
              { value: percentages['Malaria'], name: 'Malaria', itemStyle: { color: 'rgba(58, 123, 213, 0.7)' } },
              { value: percentages['Tuberculosis'], name: 'Tuberculosis', itemStyle: { color: 'rgba(0, 0, 0, 0.7)' } },
            ],
          },
        ],
      };

      if (this.chartInstance) {
        this.chartInstance.setOption(option);
      }
    },
    fetchChartData() {
      let totalResponses = 35;

      let hivPercentage = 0.70;
      let malariaPercentage = 0.20; 
      let tbPercentage = 0.10; 

      let hivCount = Math.floor(totalResponses * hivPercentage);
      let malariaCount = Math.floor(totalResponses * malariaPercentage);
      let tbCount = Math.floor(totalResponses * tbPercentage);

      let remaining = totalResponses - (hivCount + malariaCount + tbCount);

      hivCount += remaining;

      let data = {
        HIV: hivCount,
        Malaria: malariaCount,
        Tuberculosis: tbCount,
      };

      this.updateChart(data);
    },
    async fetchProvinceAndDistrictData() {
      await this.fetchDistricts(); // Fetch districts based on the selected province
      this.fetchChartData(); // Update the chart after fetching districts
    },
    async fetchProvinces() {
      try {
        const response = await axios.get('/province');
        this.provinces = response.data.data;
      } catch (error) {
        console.error("Error fetching provinces:", error);
      }
    },
    async fetchDistricts() {
      if (!this.selectedProvince) return;
      try {
        const response = await axios.get(`/districts/${this.selectedProvince}`);
        this.districts = response.data.data;
      } catch (error) {
        console.error("Error fetching districts:", error);
      }
    },
    resizeChart() {
      if (this.chartInstance) {
        this.chartInstance.resize();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  width: 515px;
  height: 450px;
  box-shadow: 0 0 14px rgb(206, 201, 201);
  background-color: white;
  border-radius: 15px;
  padding: 24px;

  .card-header {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 12px;
    padding-bottom: 16px;

    h3 {
      margin-top: -50px;
    }
  }

  .content {
    display: flex;
    align-items: flex-start;
    gap: 20px;
  }

  .chart-container {
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dropdown-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 160px;
    margin-top: 50px;

    select {
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 5px;
      background-color: transparent;
      color: #39325a;
      width: 100%;
    }
  }
}
</style>
