<template>
  <div class="card">
    <div class="card-header">
      <h3>Urgency Analysis Per Tool and Location</h3>
      <div class="legends">
        <div class="item">
          <div class="box low"></div>
          <div class="label">Low</div>
        </div>
        <div class="item">
          <div class="box medium"></div>
          <div class="label">Medium</div>
        </div>
        <div class="item">
          <div class="box high"></div>
          <div class="label">High</div>
        </div>
      </div>
    </div>
    <div class="chart-container" ref="chartContainer"></div>

    <div class="dropdown-container">
      <div class="container">
        <select v-model="selectedTool" @change="fetchChartData">
          <option disabled value="">Select Tool</option>
          <option value="1" v-if="hasPermission('tools', 'patient_tool')">Patient Tool</option>
          <option value="2" v-if="hasPermission('tools', 'health_facility_tool')">Health Facility</option>
          <option value="3" v-if="hasPermission('tools', 'community_based_tool')">Community Based</option>
          <option value="4" v-if="hasPermission('tools', 'observation_tool')">Observation Tool</option>
        </select>

        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
            stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>

      <div class="container">

        <select v-model="selectedProvince" @change="fetchDistricts">
          <option disabled value="">Select Province</option>
          <option v-for="province in provinces" :key="province.id" :value="province.id">{{ province.name }}</option>
        </select>

        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
            stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>

      <div class="container">

        <select v-model="selectedDistrict" @change="fetchChartData">
          <option disabled value="">Select District</option>
          <option v-for="district in districts" :key="district.id" :value="district.id">{{ district.name }}</option>
        </select>

        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
            stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
    </div>

  </div>
</template>


<script>
import * as echarts from 'echarts';
import axios from "@/helpers/axios.js";
import { mapGetters } from 'vuex';
import { nextTick } from 'vue';


export default {
  data() {
    return {
      provinces: [],
      districts: [],
      selectedTool: "",
      selectedProvince: "",
      selectedDistrict: "",
      chartInstance: null, // ECharts instance
    };
  },
  mounted() {
    this.fetchProvinces();
    this.initChart(); // Initialize ECharts on mount
    this.fetchChartData(); // Fetch initial data
    this.$nextTick(() => {
      if (this.chartInstance) {
        // this.chartInstance.resize();
      }
    });
  },
  computed: {
    ...mapGetters({
      hasPermission: 'hasPermission'
    }),
  },
  methods: {
    initChart() {
      this.$refs.chartContainer.style.height = '500px';
      this.chartInstance = echarts.init(this.$refs.chartContainer);
      this.updateChart([], [], [], []); // Initialize chart with empty data
    },
    async fetchProvinces() {
      try {
        const response = await axios.get('/province');
        this.provinces = response.data.data;
      } catch (error) {
        console.error("Error fetching provinces:", error);
      }
    },
    async fetchDistricts() {
      try {
        const response = await axios.get(`/districts/${this.selectedProvince}`);
        this.districts = response.data.data;
        this.fetchChartData(); // Fetch data when a province is selected
      } catch (error) {
        console.error("Error fetching districts:", error);
      }
    },
    async fetchChartData() {
      try {
        const response = await axios.get('/urgency-analysis', {
          params: {
            tool_id: this.selectedTool,
            province_id: this.selectedProvince,
            district_id: this.selectedDistrict,
          },
        });
        const chartData = response.data.data;
        const firstKey = Object.keys(chartData)[0];

        if (typeof chartData[firstKey].low !== 'undefined') {
          this.prepareChartData(chartData);
        }
      } catch (error) {
        console.error("Error fetching chart data:", error);
      }
    },
    prepareChartData(data) {
      const labels = Object.keys(data);
      const lowData = [];
      const mediumData = [];
      const highData = [];

      labels.forEach(label => {
        lowData.push(data[label].low ?? 0);
        mediumData.push(data[label].medium ?? 0);
        highData.push(data[label].high ?? 0);
      });

      // Update chart with the prepared data
      this.updateChart(labels, lowData, mediumData, highData);
    },

    updateChartWithTools(data) {
      const toolNames = {
        '1': 'Patient Tool',
        '2': 'Health Facility',
        '3': 'Community Based',
        '4': 'Observation Tool',
      };

      const labels = Object.keys(data).map(toolId => toolNames[toolId] || 'Unknown Tool');
      const lowData = [];
      const mediumData = [];
      const highData = [];

      Object.keys(data).forEach(toolId => {
        const tool = data[toolId];
        lowData.push(tool.low ?? 0); // Default to 0 if undefined
        mediumData.push(tool.medium ?? 0);
        highData.push(tool.high ?? 0);
      });

      this.updateChart(labels, lowData, mediumData, highData);
    },
    updateChartWithConditions(data) {
      const labels = Object.keys(data);
      const lowData = labels.map(label => data[label].low);
      const mediumData = labels.map(label => data[label].medium);
      const highData = labels.map(label => data[label].high);

      this.updateChart(labels, lowData, mediumData, highData);
    },
    updateChart(labels, lowData, mediumData, highData) {
      const series = [
        {
          name: 'Low',
          type: 'bar',
          stack: 'total',
          data: lowData.map((value, index) => ({
            value,
            itemStyle: {
              borderRadius: highData[index] === 0 && mediumData[index] === 0 ? [10, 10, 0, 0] : [0, 0, 0, 0],
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#A8E6CF' }, // Light green
                { offset: 1, color: '#31B063' }  // Dark green
              ]),
            }
          })),
          barWidth: '50%' // Adjust bar width for better visibility
        },
        {
          name: 'Medium',
          type: 'bar',
          stack: 'total',
          data: mediumData.map((value, index) => ({
            value,
            itemStyle: {
              borderRadius: highData[index] === 0 ? [10, 10, 0, 0] : [0, 0, 0, 0],
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#FFD974' }, // Light yellow
                { offset: 1, color: '#FFD54F' }  // Dark yellow
              ]),
            }
          })),
          barWidth: '30%'
        },
        {
          name: 'High',
          type: 'bar',
          stack: 'total',
          data: highData.map(value => ({
            value,
            itemStyle: {
              borderRadius: [10, 10, 0, 0],
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#FF8A80' }, // Light red
                { offset: 1, color: '#D32F2F' }  // Dark red
              ]),
            }
          })),
          barWidth: '30%'
        }
      ];

      const option = {
        tooltip: {
          show: true,  // Explicitly enable tooltip
          trigger: 'axis',
          axisPointer: { type: 'shadow' },
          confine: true,
          formatter: function (params) {
            console.log("Tooltip Params:", params); // Debugging
            let tooltipText = `<strong>${params[0].axisValue}</strong><br/>`;
            params.forEach(item => {
              tooltipText += `${item.marker} ${item.seriesName}: ${item.data?.value ?? 0}<br/>`;
            });
            return tooltipText;
          }
        },
        xAxis: {
          type: 'category',
          data: labels,
          axisLabel: { rotate: 30 }
        },
        yAxis: {
          type: 'value',
          max: 'dataMax',
          splitLine: { show: false } // Remove horizontal lines
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '15%',
          top: '5%',
          containLabel: true
        },
        series: series
      };

      if (this.chartInstance) {
        this.chartInstance.setOption(option);
      }
    }


  }
};
</script>

<style scoped>
.card {
  /* width: 865px; */
  max-width: 90vw;
  margin-left: 50px;
  height: 450px;
  box-shadow: 0 0 14px rgb(206, 201, 201);
  background-color: white;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
}

.card-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 24px;

  .legends {
    display: flex;
    align-items: center;
    gap: 13px;
    justify-content: right;
    width: 100%;

    .item {
      display: flex;
      align-items: center;
      gap: 6px;

      .label {
        color: #667085;
      }

    }

    .box {
      width: 8px;
      height: 8px;
      border-radius: 50px;
    }

    .low {
      background-color: #31B063;
    }

    .medium {
      background-color: #ECE637;
    }

    .high {
      background-color: #D32F2F;
    }
  }

}

.chart-container {
  width: 100%;
  height: 500px !important;
}

.dropdown-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 10px;
  margin-top: 20px;



  select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    appearance: none;
    background-color: transparent;
    color: #39325a;
  }
}

.container {
  position: relative;
  width: 100%;



  svg {
    position: absolute;
    top: 60%;
    right: 0;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
}

select {

  margin-top: 10px;
  padding: 5px;
  border-radius: 8px;
  border: 1px solid #D0D5DD;
  width: 100%;
  font-size: 1em;
  outline: none;
  padding-left: 16px;
  background-color: transparent;
  color: #39325a;

}

.legends .item .box.low {
  background-color: #31B063;
}

.legends .item .box.medium {
  background-color: #ECE637;
}

.legends .item .box.high {
  background-color: #D32F2F;
}
</style>
