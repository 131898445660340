<template>
  <div class="community_member-container">
    <div class="c-header">
      <div class="left">
        <div class="title">
          <p>All Sessions</p>
        </div>
        <div class="input-container">
          <input type="text" autocomplete="off" spellcheck="false" placeholder="Search Session" v-model="searchQuery"
            @input="applyFilters" />

          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
              stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
      </div>
      <div class="right" v-if="hasPermission('other', 'create_session')">
        <button @click="openModal">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M1.55201 3.75629C1.5 4.03196 1.5 4.36291 1.5 5.02481V10.3125C1.5 13.1409 1.5 14.5551 2.37868 15.4338C3.25736 16.3125 4.67157 16.3125 7.5 16.3125H10.5C13.3284 16.3125 14.7426 16.3125 15.6213 15.4338C16.5 14.5551 16.5 13.1409 16.5 10.3125V8.66093C16.5 6.68665 16.5 5.69951 15.9229 5.05787C15.8698 4.99885 15.8136 4.94268 15.7546 4.8896C15.113 4.3125 14.1258 4.3125 12.1516 4.3125H11.8713C11.0061 4.3125 10.5734 4.3125 10.1703 4.19758C9.94879 4.13445 9.73533 4.04603 9.53409 3.93407C9.16775 3.73025 8.86183 3.42433 8.25 2.8125L7.83731 2.39981C7.63224 2.19474 7.52971 2.09221 7.42196 2.00289C6.95739 1.61778 6.38749 1.38171 5.78668 1.32553C5.64732 1.3125 5.50232 1.3125 5.21231 1.3125C4.55041 1.3125 4.21946 1.3125 3.94379 1.36451C2.73023 1.59348 1.78098 2.54273 1.55201 3.75629ZM9 8.25C9.31066 8.25 9.5625 8.50184 9.5625 8.8125V9.75H10.5C10.8107 9.75 11.0625 10.0018 11.0625 10.3125C11.0625 10.6232 10.8107 10.875 10.5 10.875H9.5625V11.8125C9.5625 12.1232 9.31066 12.375 9 12.375C8.68934 12.375 8.4375 12.1232 8.4375 11.8125V10.875H7.5C7.18934 10.875 6.9375 10.6232 6.9375 10.3125C6.9375 10.0018 7.18934 9.75 7.5 9.75H8.4375V8.8125C8.4375 8.50184 8.68934 8.25 9 8.25Z"
              fill="white" />
          </svg>
          <span>Add Session</span>
        </button>
      </div>
    </div>

    <div class="community_member-body">
      <div class="all-session-container">
        <div class="all-session-body" v-if="hasPermission('tools', 'patient_tool')">
          <div v-for="session in sessions" :key="session.id" class="session-container"
            :class="{ activeSession: session.active === '0' }" @click="viewSession(session.id)">
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.5"
                d="M2.5 12C2.5 7.28595 2.5 4.92893 3.96447 3.46447C5.42893 2 7.78595 2 12.5 2C17.214 2 19.5711 2 21.0355 3.46447C22.5 4.92893 22.5 7.28595 22.5 12C22.5 16.714 22.5 19.0711 21.0355 20.5355C19.5711 22 17.214 22 12.5 22C7.78595 22 5.42893 22 3.96447 20.5355C2.5 19.0711 2.5 16.714 2.5 12Z"
                stroke="#1C274C" stroke-width="1.5" />
              <path
                d="M10.625 8.875C10.625 7.83947 11.4645 7 12.5 7C13.5355 7 14.375 7.83947 14.375 8.875C14.375 9.56245 14.005 10.1635 13.4534 10.4899C12.978 10.7711 12.5 11.1977 12.5 11.75V13"
                stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" />
              <circle cx="12.5" cy="16" r="1" fill="#1C274C" />
            </svg>

            <p>{{ session.name }}</p>
          </div>
          <div v-if="error" class="error">
            {{ error }}
          </div>
        </div>
        <div v-else>
          <center>
            <p class="select_tool"><i class="fa-solid fa-triangle-exclamation"></i> Select Tool
            </p>
          </center>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-wrapper">
    <teleport to="body">
      <transition name="fade">
        <div v-if="showModal" class="modal-overlay" @click="closeModal">
          <div class="modal" @click.stop>
            <div class="modal-header">
              <p>New Session</p>

              <svg @click="closeModal" width="36" height="36" viewBox="0 0 36 36" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M28.5 9.615L26.385 7.5L18 15.885L9.615 7.5L7.5 9.615L15.885 18L7.5 26.385L9.615 28.5L18 20.115L26.385 28.5L28.5 26.385L20.115 18L28.5 9.615Z"
                  fill="#757780" />
              </svg>
            </div>
            <div class="modal-body">
              <form @submit.prevent="createSession">
                <div class="modal-body-container">
                  <div class="input-container">
                    <label for="">Session Name</label>
                    <input type="text" v-model="sessionName" spellcheck="false" autocomplete="off" name="" required
                      id="" placeholder="Session Name" />
                  </div>

                  <div class="btn-container">
                    <button :disabled="loading">
                      <span v-if="loading" class="spinner"></span>
                      <svg v-if="!loading" width="12" height="12" viewBox="0 0 12 12" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6.75 0H5.25V5.25002L0 5.24999V6.74999L5.25 6.75002V12H6.75V6.75002H12V5.25002L6.75 5.24999V0Z"
                          fill="white" />
                      </svg>
                      <span v-if="!loading">Create Session</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </transition>
    </teleport>
  </div>
</template>

<script>
import axios from "@/helpers/axios.js";
import { useToast } from "vue-toastification";
import { mapGetters } from "vuex";

const Toast = useToast();

export default {
  data() {
    return {
      showModal: false,
      sessionName: "",
      language_id: "",
      searchQuery: "",
      selectedLanguage: "",
      loading: false,
      sessions: [],
      error: null,
    };
  },
  computed: {
    ...mapGetters({
      hasPermission: "hasPermission",
    }),
  },
  mounted() {
    this.fetchSessions();
  },
  methods: {
    closeModal() {
      this.showModal = false;
      this.comments = [];
    },
    openModal() {
      this.showModal = true;
    },
    applyFilters() {
      console.log("Emitting filters:", {
        searchQuery: this.searchQuery,
        selectedLanguage: this.selectedLanguage,
      });

      this.fetchSessions();
    },
    viewSession(id) {
      this.$router.push({
        name: 'health_facility_tool_uploads_session_files',
        params: { id: id },
      });
    },
    fetchSessions() {
      const params = {};
      if (this.searchQuery) {
        params.searchQuery = this.searchQuery;
      }
      if (this.selectedLanguage) {
        params.language_id = this.selectedLanguage;
      }
      console.log(params, "Fetching with params");
      axios
        .get("/admin/sessions/tool/2", { params })
        .then((response) => {
          if (response.data.status) {
            this.sessions = response.data.data;
          } else {
            this.error = response.data.message;
          }
        })
        .catch((error) => {
          this.error = "Failed to load sessions. Please try again later.";
          console.error(error);
        });
    },
    async createSession() {
      if (!this.sessionName) {
        Toast.error("Session Name is required");
        return;
      }

      this.loading = true;
      try {
        const response = await axios.post("/admin/sessions", {
          name: this.sessionName,
          tool_id: 2,
          project_id: 1,
        });

        console.log("Response:", response);

        if (response.data.status) {
          Toast.success(response.data.message);

          const sessionId = response.data.data.id;
          console.log("Session ID:", sessionId);

          this.$router.push({
            name: "health_facility_tool_uploads_session_files",
            params: { id: sessionId },
          });
        } else {
          Toast.error(response.data.message);
        }
      } catch (error) {
        Toast.error("Failed to create session");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.community_member-container {

  padding: 20px 0;

  .c-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(33, 72, 192, 0.1);
    height: 64px;
    border-radius: 8px;
    padding: 8px;

    .left {
      display: flex;
      align-items: center;
      gap: 25px;

      .title {
        padding: 10px 16px;


        p {
          font-size: 26px;
          font-weight: bold;
          color: #003E8F;
        }
      }

      .input-container {
        position: relative;

        input {
          width: 368px;
          height: 48px;
          border-radius: 8px;
          border: 1px solid #D0D5DD;
          outline: none;
          padding-left: 42px;
          padding-right: 14px;
          font-size: 16px;
          color: #667085;


          &::placeholder {
            font-size: 16px;
            color: #667085;
          }
        }


        svg {
          position: absolute;
          top: 14px;
          left: 14px;
        }
      }
    }

    .right {
      padding: 4px 0;

      button {
        height: 48px;
        width: 151px;
        border-radius: 8px;
        border: none;
        background-color: rgba(0, 106, 245, 1);
        color: white;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        cursor: pointer;
        transition: 200ms ease;

        &:hover {
          background-color: rgb(4, 91, 206);
        }
      }
    }
  }


  .community_member-body {
    width: 100%;
  }
}

.all-session-container {
  width: 100%;
  // min-height: 20vh;
  background-color: var(--white);
  padding: 24px;
  border: 1px solid #E4E7EC;
  border-radius: 8px;
  margin-top: 16px;

  .all-session-header {
    .search-input-container {
      width: 100%;
      position: relative;
      border: 2px solid var(--blue-500);
      border-radius: 50px;
      overflow: hidden;

      input {
        width: 100%;
        padding: 12px;
        border: none;
        outline: none;
        color: var(--blue-500);
      }

      .icon {
        position: absolute;
        top: 9px;
        right: 20px;
      }
    }
  }

  .select_tool {
    font-size: 12px !important;
  }

  .all-session-body {
    width: 100%;
    // padding-top: 20px;
    display: flex;
    gap: 24px;
    flex-wrap: wrap;




    .session-container {

      width: 254px;
      min-height: 65px;
      background-color: var(--white);

      font-size: 20px;
      padding: 5px 10px;
      transition: 300ms ease;
      border: 1px solid #E4E7EC;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      box-shadow: 0px 8px 7px -5px rgba(16, 24, 40, 0.1);


      &:hover {
        background-color: rgba(0, 106, 245, 0.05);
        border: 1px solid rgba(33, 72, 192, 0.5);

        p {
          font-size: 16px;
          font-weight: bold;
        }

        svg {
          path {
            fill: #1C274C;
            fill-opacity: 1;
            opacity: 1;
            stroke: white;
          }

          circle {
            fill: white;
          }
        }
      }

      p {
        font-size: 16px;
        font-weight: 400;
      }

      svg {
        path {
          transition: all 200ms ease;
          // fill: red;
          // stroke:  white;
        }

        circle {
          transition: all 200ms ease;
          // fill: white;
        }
      }



      &:hover {

        cursor: pointer;
      }
    }

    .activeSession {



      p {
        font-size: 16px;
        font-weight: bold;
      }

      svg {
        path {
          fill: #1C274C;
          fill-opacity: 1;
          opacity: 1;
          stroke: white;
        }

        circle {
          fill: white;
        }
      }

    }
  }
}

.modal-wrapper {
  position: relative
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  z-index: 99999999;
}

.modal {
  background: white;
  padding: 32px;
  border-radius: 10px;
  transform: scale(1);
  transition: 300ms ease;
  z-index: 99999;
  width: 542px;
  min-height: 258px;

  position: relative;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;

  .modal-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    position: sticky;
    top: 0;

    p {
      font-size: 20px;
      color: #344054;
      font-weight: 400;
    }

    svg {
      cursor: pointer;
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    gap: 30px;


    .modal-body-container {
      display: flex;
      gap: 30px;
      flex-direction: column;
      gap: 24px;

      .input-container {
        display: flex;
        flex-direction: column;
        gap: 6px;


        label {
          font-size: 14px;
          color: #344054;
        }


        input {
          padding: 12px 14px;
          border-radius: 8px;
          border: 1px solid #D0D5DD;
          outline: 2px solid transparent;
          transition: 200ms ease;
          color: #344054;


          &:focus,
          &:valid {
            outline: 2px solid #5582e2;
          }


          &::placeholder {
            font-size: 16px;
          }
        }

      }

      .btn-container {
        button {
          width: 169px;
          height: 44px;
          border: none;
          border-radius: 8px;


          background-color: rgba(0, 106, 245, 1);
          color: white;
          font-size: 14px;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          cursor: pointer;
          transition: 200ms ease;

          &:hover {
            background-color: rgb(4, 91, 206);
          }
        }
      }
    }
  }
}

.fade-enter-active {
  $modalOpen: True;

  @if $modalOpen ==True {
    .modal {
      transform: scale(0);
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transform: scale(1);
  transition: opacity 500ms linear;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.select-container select {
  padding: 12px 14px;
  border-radius: 8px;
  border: 1px solid #D0D5DD;
  outline: 2px solid transparent;
  transition: 200ms ease;
  color: #344054;
  width: 100%;
  font-size: 16px;
  background-color: white;
  appearance: none;

  &:focus{
    outline: 2px solid #5582e2;
  }

  &::placeholder {
    font-size: 16px;
  }
}

.select-container {
  position: relative;
}

.select-container::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 14px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #344054;
  transform: translateY(-50%);
  pointer-events: none;
}

.spinner {
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 3px solid white;
  width: 16px;
  height: 16px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  margin-right: 8px;
  display: inline-block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.language {
  font-size: 14px;
  margin-left: 25px;
}
</style>
