<template>
    <div class="form-container">
        <div class="card">
            <div class="card-header">
                <h2>Questionnaire</h2><br>
            </div>
            <form @submit.prevent="handleSubmit">
                <div v-for="(question, index) in questions" :key="index" class="question-block">
                    <div class="question-row">
                        <select v-model="question.type" class="select-box" @change="saveToLocalStorage">
                            <option value="">Select Type</option>
                            <option value="1">Open Question</option>
                            <option value="2">Closed Question</option>
                        </select>

                        <select v-model="question.code" class="select-box" @change="saveToLocalStorage">
                            <option value="">Select Code</option>
                            <option value="1">Single</option>
                            <option value="2">Multiple</option>
                            <option value="3">Written</option>
                            <option value="4">Fully Written</option>
                        </select>
                        <input v-model="question.kinyarwandaQuestion" placeholder="Kinyarwanda Question"
                            class="input-box" @input="saveToLocalStorage" />
                        <input v-model="question.englishQuestion" placeholder="English Question" class="input-box"
                            @input="saveToLocalStorage" />
                        <div class="response-section">
                            <div v-for="(response, rIndex) in question.responses" :key="rIndex" class="response-row">
                                <input v-model="response.response" placeholder="Kinyarwanda Response"
                                    class="response-box" @input="saveToLocalStorage" />
                                <input v-model="response.eng_response" placeholder="English Response"
                                    class="response-box" @input="saveToLocalStorage" />
                                <button type="button" class="remove-btn"
                                    @click="removeResponse(index, rIndex)">-</button>
                            </div>
                            <button type="button" class="add-response-btn" @click="addResponse(index)">+ Add
                                Response</button>
                        </div>

                        <button type="button" class="remove-btn" @click="removeQuestion(index)">Remove</button>
                    </div>
                </div>

                <button type="button" class="add-question-btn" @click="addQuestion">+ Add Question</button>
                <button type="submit" class="submit-btn">Save Changes</button>
            </form>
        </div>
    </div>
</template>

<script>
import axios from "@/helpers/axios.js";
import { useToast } from "vue-toastification";

const Toast = useToast();

export default {
    data() {
        return {
            sessionId: this.getSessionIdFromUrl(),
            questions: JSON.parse(localStorage.getItem(`questionnaireData_${this.getSessionIdFromUrl()}`)) || [
                {
                    type: "",
                    code: "",
                    kinyarwandaQuestion: "",
                    englishQuestion: "",
                    responses: [{ response: "", eng_response: "" }],
                },
            ],
        };
    },
    methods: {
        getSessionIdFromUrl() {
            const segments = window.location.href.split("/");
            return segments[segments.length - 1];
        },
        saveToLocalStorage() {
            localStorage.setItem(`questionnaireData_${this.sessionId}`, JSON.stringify(this.questions));
        },
        addQuestion() {
            this.questions.push({
                type: "",
                code: "",
                kinyarwandaQuestion: "",
                englishQuestion: "",
                responses: [{ response: "", eng_response: "" }],
            });
            this.saveToLocalStorage();
        },
        removeQuestion(index) {
            this.questions.splice(index, 1);
            this.saveToLocalStorage();
        },
        addResponse(questionIndex) {
            this.questions[questionIndex].responses.push({ response: "", eng_response: "" });
            this.saveToLocalStorage();
        },
        removeResponse(questionIndex, responseIndex) {
            this.questions[questionIndex].responses.splice(responseIndex, 1);
            this.saveToLocalStorage();
        },
        async handleSubmit() {
            try {
                const formattedQuestions = this.questions.map(q => ({
                    tool_id: 4,
                    session_id: this.sessionId,
                    question_type_id: q.type,
                    question_code: q.code,
                    question: q.kinyarwandaQuestion,
                    eng_question: q.englishQuestion,
                    responses: q.responses.map(r => ({
                        response: r.response,
                        eng_response: r.eng_response
                    }))
                }));

                await axios.post("/questionnaire", { questions: formattedQuestions });
                Toast.success("Questionnaire submitted successfully");

                // Clear form and localStorage after successful submission
                this.questions = [{
                    type: "",
                    code: "",
                    kinyarwandaQuestion: "",
                    englishQuestion: "",
                    responses: [{ response: "", eng_response: "" }],
                }];
                localStorage.removeItem(`questionnaireData_${this.sessionId}`);
            } catch (error) {
                Toast.error("Failed to submit questionnaire");
                console.error(error);
            }
        },
    },
};
</script>

<style scoped>
.form-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.card {
    width: 100%;
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.card-header h2 {
    text-align: center;
    color: #003E8F;
}

.question-block {
    border: 1px solid #003982;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 15px;
    background: #fbfbfbb8;
}

.question-row {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.select-box,
.input-box {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.response-section {
    margin-top: 5px;
}

.response-row {
    display: flex;
    align-items: center;
    gap: 5px;
}

.response-box {
    padding: 6px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 150px;
}

.add-response-btn {
    background: #28a745;
    color: white;
    border: none;
    padding: 4px 6px;
    border-radius: 5px;
    cursor: pointer;
    display: block;
    margin: 5px auto;
    font-size: 12px;
}

.add-question-btn {
    background: #28a745;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
}

.remove-btn {
    background: #dc3545;
    color: white;
    border: none;
    padding: 5px 8px;
    border-radius: 5px;
    cursor: pointer;
}

.submit-btn {
    background: #006AF5;
    color: white;
    border: none;
    padding: 6px 10px;
    border-radius: 5px;
    cursor: pointer;
    display: block;
    width: 150px;
    margin: 10px auto;
}
</style>