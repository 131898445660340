<template>
    <div class="form-container">
        <div class="tabs-container">
            <button :class="{ active: activeTab === 'introductory' }"
                @click="switchTab('introductory')">Introductory</button>
            <button :class="{ active: activeTab === 'questionnaire' }"
                @click="switchTab('questionnaire')">Questionnaire</button>
        </div><br>
        <div class="card">
            <div class="card-header">
                <h2>{{ activeTab === 'introductory' ? 'Introductory' : 'Questionnaire' }}</h2>
            </div>
            <form @submit.prevent="handleSubmit">
                <div v-for="(question, index) in questions" :key="index" class="question-block">
                    <div class="question-row">
                        <select v-model="question.type" class="select-box" @change="saveToLocalStorage">
                            <option value="">Select Type</option>
                            <option value="1">Open Question</option>
                            <option value="2">Closed Question</option>
                        </select>

                        <select v-model="question.code" class="select-box" @change="saveToLocalStorage">
                            <option value="">Select Code</option>
                            <option value="1">Single</option>
                            <option value="2">Multiple</option>
                            <option value="3">Written</option>
                            <option value="4">Fully Written</option>
                        </select>

                        <template v-if="activeTab === 'questionnaire'">
                            <select v-model="question.condition" class="select-box"
                                @change="handleConditionChange(index)">
                                <option value="">Select Condition</option>
                                <option value="1">HIV</option>
                                <option value="2">Malaria</option>
                                <option value="3">Tuberculosis</option>
                            </select>

                            <select v-model="question.sub_condition" class="select-box"
                                v-if="question.condition === '1' && question.showKVP">
                                <option value="" disabled>Select KVP</option>
                                <option value="1">KVP</option>
                            </select>
                        </template>

                        <input v-model="question.kinyarwandaQuestion" placeholder="Kinyarwanda Question"
                            class="input-box" @input="saveToLocalStorage" />
                        <input v-model="question.englishQuestion" placeholder="English Question" class="input-box"
                            @input="saveToLocalStorage" />

                        <div class="response-section">
                            <div v-for="(response, rIndex) in question.responses" :key="rIndex" class="response-row">
                                <input v-model="response.response" placeholder="Kinyarwanda Response"
                                    class="response-box" @input="saveToLocalStorage" />
                                <input v-model="response.eng_response" placeholder="English Response"
                                    class="response-box" @input="saveToLocalStorage" />
                                <button type="button" class="remove-btn"
                                    @click="removeResponse(index, rIndex)">-</button>
                            </div>
                            <button type="button" class="add-response-btn" @click="addResponse(index)">+ Add
                                Response</button>
                        </div>

                        <button type="button" class="remove-btn" @click="removeQuestion(index)">Remove</button>
                    </div>
                </div>

                <button type="button" class="add-question-btn" @click="addQuestion">+ Add Question</button>
                <button type="submit" class="submit-btn">Save Changes</button>
            </form>
        </div>
    </div>
    <div v-if="showModal" class="modal-overlay">
        <div class="modal">
            <p><b>Is this question KVP?</b></p><br>
            <button class="modal-btn yes" @click="confirmKVP(true)">Yes</button>
            <button class="modal-btn no" @click="confirmKVP(false)">No</button>
        </div>
    </div>
</template>

<script>
import axios from "@/helpers/axios.js";
import { useToast } from "vue-toastification";

const Toast = useToast();

export default {
    data() {
        return {
            sessionId: this.getSessionIdFromUrl(),
            activeTab: "questionnaire",
            questions: [],
            showModal: false,
            currentQuestionIndex: null
        };
    },
    created() {
        this.loadQuestions();
    },
    methods: {
        getSessionIdFromUrl() {
            const segments = window.location.href.split("/");
            return segments[segments.length - 1];
        },
        switchTab(tab) {
            this.activeTab = tab;
            this.loadQuestions();
        },
        loadQuestions() {
            const storageKey = this.activeTab === "introductory" ? `introductoryData_${this.sessionId}` : `questionnaireData_${this.sessionId}`;
            const savedQuestions = JSON.parse(localStorage.getItem(storageKey));

            if (savedQuestions && savedQuestions.length > 0) {
                this.questions = savedQuestions;
            } else {
                this.questions = [
                    {
                        type: "",
                        code: "",
                        kinyarwandaQuestion: "",
                        englishQuestion: "",
                        responses: [{ response: "", eng_response: "" }]
                    }
                ];
            }
        },
        saveToLocalStorage() {
            const storageKey = this.activeTab === "introductory" ? `introductoryData_${this.sessionId}` : `questionnaireData_${this.sessionId}`;

            const formattedQuestions = this.questions.map(q => {
                let questionData = {
                    type: q.type,
                    code: q.code,
                    kinyarwandaQuestion: q.kinyarwandaQuestion,
                    englishQuestion: q.englishQuestion,
                    responses: q.responses
                };

                if (this.activeTab === "questionnaire") {
                    questionData.condition = q.condition || "";
                    questionData.sub_condition = q.sub_condition || "";
                }

                return questionData;
            });

            localStorage.setItem(storageKey, JSON.stringify(formattedQuestions));
        },

        addQuestion() {
            this.questions.push({
                type: "",
                code: "",
                condition: "",
                sub_condition: "",
                kinyarwandaQuestion: "",
                englishQuestion: "",
                responses: [{ response: "", eng_response: "" }],
                showKVP: false
            });
            this.saveToLocalStorage();
        },
        removeQuestion(index) {
            this.questions.splice(index, 1);
            this.saveToLocalStorage();
        },
        addResponse(questionIndex) {
            this.questions[questionIndex].responses.push({ response: "", eng_response: "" });
            this.saveToLocalStorage();
        },
        removeResponse(questionIndex, responseIndex) {
            this.questions[questionIndex].responses.splice(responseIndex, 1);
            this.saveToLocalStorage();
        },
        handleConditionChange(index) {
            if (this.questions[index].condition === "1") {
                this.currentQuestionIndex = index;
                this.showModal = true;
            } else {
                this.questions[index].showKVP = false;
                this.questions[index].sub_condition = "";
                this.saveToLocalStorage();
            }
        },
        confirmKVP(isKVP) {
            if (this.currentQuestionIndex !== null) {
                this.questions[this.currentQuestionIndex].showKVP = isKVP;
                this.saveToLocalStorage();
            }
            this.showModal = false;
        },
        async handleSubmit() {
            try {
                const formattedQuestions = this.questions.map(q => {
                    let questionData = {
                        type: this.activeTab,
                        tool_id: 1,
                        session_id: this.sessionId,
                        question_type_id: q.type,
                        question_code: q.code,
                        question: q.kinyarwandaQuestion,
                        eng_question: q.englishQuestion,
                        responses: q.responses.map(r => ({
                            response: r.response,
                            eng_response: r.eng_response
                        }))
                    };

                    if (this.activeTab === "questionnaire") {
                        questionData.condition_id = q.condition;
                        questionData.sub_condition_id = q.sub_condition;
                    }

                    return questionData;
                });


                await axios.post("/questionnaire", { questions: formattedQuestions });

                Toast.success(`${this.activeTab === "introductory" ? "Introductory" : "Questionnaire"} submitted successfully`);

                this.questions = [
                    {
                        type: "",
                        code: "",
                        kinyarwandaQuestion: "",
                        englishQuestion: "",
                        responses: [{ response: "", eng_response: "" }]
                    },
                ];
                localStorage.removeItem(this.activeTab === "introductory" ? `introductoryData_${this.sessionId}` : `questionnaireData_${this.sessionId}`);
            } catch (error) {
                Toast.error("Failed to submit data");
                console.error(error);
            }
        }

    },
};
</script>

<style scoped>
.form-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.tabs-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
    border-bottom: 2px solid #ddd;
}

.tabs-container button {
    padding: 12px 20px;
    margin: 0 5px;
    border: none;
    background: #f8f9fa;
    cursor: pointer;
    font-weight: bold;
    font-size: 15px;
    border-radius: 5px 5px 0 0;
    transition: background 0.3s, color 0.3s;
}

.tabs-container button.active {
    background: #003E8F;
    color: white;
    font-size: 15px;
    border-bottom: 3px solid #003E8F;
}

.tabs-container button:hover {
    background: #0056c2;
    color: white;
}

.card {
    width: 100%;
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.card-header h2 {
    text-align: center;
    color: #003E8F;
    font-size: 24px;
    margin-bottom: 10px;
}

.question-block {
    border: 1px solid #003982;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 15px;
    background: #fbfbfbb8;
}

.question-row {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.select-box,
.input-box {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    transition: border 0.3s;
}

.select-box:focus,
.input-box:focus {
    border: 1px solid #003E8F;
    outline: none;
}

.response-section {
    margin-top: 5px;
}

.response-row {
    display: flex;
    align-items: center;
    gap: 5px;
}

.response-box {
    padding: 6px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 150px;
}

.add-response-btn,
.add-question-btn {
    background: #28a745;
    color: white;
    border: none;
    padding: 6px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    margin: 5px auto;
    transition: background 0.3s;
}

.add-response-btn:hover,
.add-question-btn:hover {
    background: #218838;
}

.remove-btn {
    background: #dc3545;
    color: white;
    border: none;
    padding: 5px 8px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background 0.3s;
}

.remove-btn:hover {
    background: #c82333;
}

.submit-btn {
    background: #006AF5;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    display: block;
    width: 180px;
    margin: 10px auto;
    font-size: 16px;
    font-weight: bold;
    transition: background 0.3s;
}

.submit-btn:hover {
    background: #0056c2;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    background: white;
    padding: 30px;
    width: 20%;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.modal-btn {
    margin: 10px;
    padding: 8px 25px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
}

.yes {
    background: #389338;
    color: white;
    transition: background 0.3s;
}

.yes:hover {
    background: #2c7d2c;
}

.no {
    background: #c74040;
    color: white;
    transition: background 0.3s;
}

.no:hover {
    background: #a53030;
}
</style>
